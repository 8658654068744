import { grpc } from "@improbable-eng/grpc-web";
import { UnaryOutput } from "@improbable-eng/grpc-web/dist/typings/unary";
import { CampaignDetails, CampaignDetailsList } from "api/models/gamification-service/gamification_pb";
import { GamificationService } from "api/models/gamification-service/gamification_pb_service";
import { GAMIFICATION_SERVICE_HOST } from "api/serviceEndpoints";

import { firebaseAuth } from "config/firebase";
import { campaignListLoading, campaignListUpdate } from "redux/reducers/campaignReducer";
import { AppThunk } from "redux/store/store";


export const getCampaignList = (): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(campaignListLoading(true));
      const jwtToken = await firebaseAuth.currentUser?.getIdToken();
      const reqBody: CampaignDetails = new CampaignDetails();
      grpc.unary(GamificationService.GetAllCampaign, {
        host: GAMIFICATION_SERVICE_HOST,
        request: reqBody,
        metadata: {
          Authorization: `Bearer ${jwtToken}`,
        },
        onEnd: (res: UnaryOutput<CampaignDetailsList>) => {
          const { status, message, statusMessage } = res;
          console.log("campaign list", message?.toObject());
          if (status === grpc.Code.OK && message) {
            dispatch(campaignListUpdate(message.toObject()));
          } else {
            dispatch(campaignListLoading(false));
          }
        },
      });
    } catch (error) {
      dispatch(campaignListLoading(false));
      console.error(error);
    }
  };
