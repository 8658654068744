import React, { createElement, ReactElement, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";

interface CampaignProps {
  onClickBack: any;
  isEditing: any;
  editItem: any;
  lang: 0 | 1 | 2 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21;
}

const PREFIX = "CampaignForm";

const classes = {
  root: `${PREFIX}-root`,
  imgCardHeader: `${PREFIX}-imgCardHeader`,
  progress: `${PREFIX}-progress`,
  formBtn: `${PREFIX}-formBtn`,
  thumbNail: `${PREFIX}-thumbNail`,
  media: `${PREFIX}-media`,
  actionIcon: `${PREFIX}-actionIcon`,
  paper: `${PREFIX}-paper`,
  toggle: `${PREFIX}-toggle`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 250,
    width: "100%",
  },

  [`& .${classes.imgCardHeader}`]: {
    height: "20%",
    textOverflow: "ellipsis",
  },

  [`& .${classes.progress}`]: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: "50%",
  },

  [`& .${classes.formBtn}`]: {
    marginRight: "20px",
  },

  [`& .${classes.thumbNail}`]: {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    display: "block",
  },

  [`& .${classes.media}`]: {
    height: "60%",
  },

  [`& .${classes.paper}`]: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    top: `40%`,
    left: `40%`,
  },

  [`& .${classes.toggle}`]: {
    "&.MuiToggleButton-root": {
      padding: "15px 11px",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
  },
}));

export const CampaignForm = React.forwardRef<HTMLDivElement, CampaignProps>(
  (props, ref): ReactElement => {
    const quillRef = useRef<ReactQuill>(null);
    const [content, setContent] = React.useState("");
    const [isAdding, setIsAdding] = React.useState(false);

    const validateForm = () => {
      if (content.trim() === "") {
        // Add your snackbar/error handling
        return false;
      }
      return true;
    };

    const handleAddButton = () => {
      const quill = quillRef.current?.getEditor();
      const range = quill?.getSelection();

      const buttonHTML = `
      <a role="button" href="https://www.example.com" target="_blank"
         style="color: #fff; text-decoration: none; display: inline-block; font-family: Helvetica, sans-serif; font-size: 16px; padding: 10px 20px; background-color: #2cd29b; border-radius: 5px;">
         Donate Now
      </a>
    `;
      quill?.clipboard.dangerouslyPasteHTML(range?.index ?? 0, buttonHTML);
      const el = document.getElementsByClassName("ql-editor")?.[0];
      const button = el?.querySelector("a:last-of-type");
      if (button instanceof HTMLAnchorElement) {
        button.style.backgroundColor = "#2cd29b";
        button.style.color = "#fff";
        button.style.textDecoration = "none";
        button.style.display = "inline-block";
        button.style.fontFamily = "Helvetica, sans-serif";
        button.style.fontSize = "16px";
        button.style.padding = "10px 20px";
        button.style.borderRadius = "5px";
      }
    };

    const _addCampaign = () => {
      if (validateForm()) {
        const campaignData = {
          content: {
            defaultContent: content,
            en: content,
            // Add other language fields as needed
          },
          // Add other necessary fields
        };

        if (props.isEditing) {
          setIsAdding(true);
          // Add your update logic
        } else {
          setIsAdding(true);
          // Add your create logic
        }
      }
    };

    return (
      <Root ref={ref}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  sx={{ marginBottom: "10px", marginLeft: "10px" }}
                >
                  Campaign content
                </Typography>
                <Button onClick={handleAddButton}>Add button</Button>
                <ReactQuill
                  id="content-quill"
                  value={content}
                  theme="snow"
                  placeholder="Write something awesome..."
                  onChange={setContent}
                  ref={quillRef}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box p={2} display="flex" justifyContent="flex-end">
            <Button
              className={classes.formBtn}
              onClick={props.onClickBack}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              className={classes.formBtn}
              onClick={() => _addCampaign()}
              color="primary"
              variant="contained"
              disabled={isAdding}
            >
              {props.isEditing ? "Update campaign" : "Add campaign"}
            </Button>
          </Box>
        </Card>
      </Root>
    );
  }
);
