import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    FormControl,
    Grid,
    Grow,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { ReactElement, useEffect, useState } from "react";
  import AddIcon from "@mui/icons-material/Add";
  import { ListSkeleton } from "components/listSkeleton";
  import RefreshIcon from "@mui/icons-material/Refresh";
  import style from "./index.module.scss";
  import { useDispatch, useSelector } from "react-redux";
  import { RootState } from "redux/store/store";
  import { MenuProps } from "styles/global.styles";
  import { CampaignForm } from "./CampaignsForm";
import { getCampaignList } from "redux/actions/campaignActions";
  
  interface State {
    editing?: boolean;
    editItem?: any;
  }

  const initialState: State = {
    editing: false,
    editItem: null,
  };
  
  export default function CampaignsScreen(): ReactElement {
    const dispatch = useDispatch();
    const campaignList = useSelector(
      (state: RootState) => state.campaign.campaignList
    );
    const campaignStatus = ["Active", "Inactive", "Scheduled"];
  
    const languages = useSelector((state: RootState) => state.app.languages);
    const [language, setLanguage] = useState<any>(1);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [states, setStates] = useState<State>(initialState);
    const [searchCampaign, setSearchCampaign] = useState("");
    const [searchData, setSearchData] = useState<any[]>([]);
  
    const onClickBack = () => {
      setCurrentIndex(0);
      setSearchCampaign("");
      setStates({
        editing: false,
        editItem: null,
      });
    };

    useEffect(() => {
      const fetchCampaignList = () => {
        try {
          dispatch(getCampaignList());
        } catch (error) {
          console.error("error in fetching campaign list", error);
        }
      }
      fetchCampaignList();
    }, [dispatch]);

    const renderCampaignsList = (data: any) => {
      return (
        <div>
          {data.length ? (
            <>
              <Grid
                style={{ backgroundColor: "transparent" }}
                className="d-flex py-2 pl-3 align-items-center"
              >
                <Grid className="d-flex flex-grow-1">
                  <TextField
                    type="search"
                    fullWidth
                    value={searchCampaign}
                    variant="outlined"
                    placeholder="Search campaign by name"
                    size="small"
                    onChange={(e) => {
                      setSearchCampaign(e.target.value);
                      const filteredData = data.filter((item: any) =>
                        item.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                      setSearchData(filteredData);
                    }}
                    className="mr-2"
                  />
                </Grid>
                <Grid className="d-flex align-items-center">
                  <IconButton
                    onClick={() => {
                      // Add campaign refresh action here
                    }}
                    size="large"
                  >
                    <Tooltip title="Refresh">
                      <RefreshIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              </Grid>
              <TableContainer
                style={{ maxHeight: "400px" }}
                className={style.scrollbar}
              >
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow style={{ fontWeight: "bold" }}>
                      <TableCell
                        align="left"
                        sx={{
                          width: "20%",
                        }}
                      >
                        Campaign ID
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width: "30%",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width: "20%",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          width: "30%",
                        }}
                      >
                        Start Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchCampaign !== "" ? searchData : data).map(
                      (item: any, x: any) => (
                        <React.Fragment key={x}>
                          <TableRow
                            sx={{ cursor: "pointer", height: "50px" }}
                            hover
                            onClick={() => {
                              setStates({
                                editing: true,
                                editItem: item,
                              });
                              setCurrentIndex(1);
                            }}
                          >
                            <TableCell align="left">{item.id}</TableCell>
                            <TableCell align="left">
                              {item.name ? (
                                item.name
                              ) : (
                                <Chip
                                  variant="filled"
                                  label="Name not available"
                                />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {campaignStatus[item.status]}
                            </TableCell>
                            <TableCell align="left">{item.startDate}</TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Box height={350}>
              <Grid
                container
                style={{
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  No campaigns to show
                </Typography>
              </Grid>
            </Box>
          )}
        </div>
      );
    }
  
    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Card className="mt-3 mb-5">
              <Grow
                in={currentIndex === 0}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentIndex === 0 ? { timeout: 1000 } : {})}
              >
                <div>
                  <Grid container className="align-items-center p-3" spacing={2}>
                    <Grid item>
                      <Typography variant="h6">Donation Campaigns</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setCurrentIndex(1);
                        }}
                        startIcon={<AddIcon />}
                      >
                        Add Campaign
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={3}>
                    <FormControl variant="outlined" className="m-3" fullWidth>
                      <InputLabel id="language-select">Language</InputLabel>
                      {languages && (
                        <Select
                          defaultValue=""
                          labelId="language-select"
                          value={language}
                          label="Language"
                          onChange={(e) => {
                            setLanguage(e.target.value as number);
                          }}
                          MenuProps={MenuProps}
                        >
                          {languages.map((lang: any, i: any) => (
                            <MenuItem key={i} value={lang.code}>
                              {lang.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </Grid>
                  <Divider />
                  <CardContent>
                    <Paper>
                      {campaignList.loading
                        ? ListSkeleton()
                        : renderCampaignsList(campaignList.campaignList.campaigndetailsList)}
                    </Paper>
                  </CardContent>
                </div>
              </Grow>
              <Grow
                in={currentIndex === 1}
                mountOnEnter
                unmountOnExit
                style={{ transformOrigin: "0 0 0" }}
                {...(currentIndex === 1 ? { timeout: 1000 } : {})}
              >
                <CampaignForm
                  isEditing={states.editing}
                  editItem={states.editItem}
                  onClickBack={onClickBack}
                  lang={language}
                />
              </Grow>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }