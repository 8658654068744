import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignDetailsList } from "api/models/gamification-service/gamification_pb";

interface CampaignDefaultState {
  campaignList: {
    loading: boolean;
    campaignList: CampaignDetailsList.AsObject;
  };
}

const initialState: CampaignDefaultState = {
  campaignList: {
    loading: true,
    campaignList: {
      campaigndetailsList: [],
    },
  },
};

export const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
      campaignListLoading: (state, action: PayloadAction<boolean>) => {
        state.campaignList.loading = action.payload;
      },
      campaignListUpdate: (
        state,
        action: PayloadAction<CampaignDetailsList.AsObject>
      ) => {
        state.campaignList.loading = false;
        state.campaignList.campaignList = action.payload;
      }
    },
  });

export const { campaignListLoading, campaignListUpdate } = campaignSlice.actions;

export default campaignSlice.reducer;
