import { ReactElement, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
//import DashboardScreen from "pages/app/dashboard/dashboard.screen";
import GuidedMeditationScreen from "pages/app/guided/guidedMeditation.screen";
import LiveMeditationScreen from "pages/app/live/liveMeditation.screen";
import CoachesScreen from "pages/app/coaches/coaches.screen";
//import MeditatorsScreen from "pages/app/meditators/meditators.screen";
import GoalsScreen from "pages/app/goals/goals.screen";
import GoalsV2Screen from "pages/app/goalsv2/goalsv2.screen";
import DonationsScreen from "./donations/donations.screen";
//import AnalyticsScreen from "pages/app/analytics/analytics.screen";
//import SettingsScreen from "pages/app/settings/settings.screen";
import React from "react";
import DashboardLayout from "components/dashboard/dashboardLayout";
//import NotificationsScreen from "./notifications/notification.screen";
//import SessionBenifitsScreen from "./benifits/benifits.screen";
import UsersScreen from "./createUser/users.screen";
import MeditationWithPurposeScreen from "./guidedMeditations/meditationwithpurpose/meditationWithPurpose.screen";
import PublicRoute from "components/publicRoute";
import DashboardScreen from "./dashboard/dashboard.screen";
import LayoutmanagerScreen from "./layoutmanager/layoutmanager.screen";
import { initializePushNotifications } from "config/pushNotifications";
import AnnouncementsScreen from "./bhandara/announcements/announcements.screen";
import EssentialServicesScreen from "./bhandara/essentialServices/essentialServices.screen";
import LostAndFoundScreen from "./bhandara/lostandfound/LostAndFound.screen";
import LocationsScreen from "./bhandara/locations/locations.screen";
import EventsScreen from "./bhandara/events/events.screen";
import MoodsScreen from "./moods/moods.screen";
import PurposeCategoryScreen from "./guidedMeditations/purposeCategory/purposeCategory.screen";
import ArticlesScreen from "./resources/articles/articles.screen";
import BlogsScreen from "./blogs/blogs.screen";
import VideosScreen from "./resources/videos/videos.screen";
import QuotesScreen from "./resources/quotes/quotes.screen";
import FiltersScreen from "./resources/filters/filters.screen";
import TriggersScreen from "./triggers/triggers.screen";
import MeditaionBackground from "./guidedMeditations/meditationbackground/meditationBackground.screen";
import faqScreen from "./faq/faq.screen";
import PreRecordedVideosScreen from "./groups/preRecordedVideos/preRecordedVideos.screen";
import SchedulePreRecordedVideoScreen from "./groups/schedulePreRecordedVideo/schedulePreRecordedVideo.screen";
import CreateLiveSessionScreen from "./createLiveSession/createLiveSession.screen";
import { AssignLiveSessionScreen } from "./assignLiveSession/assignLiveSession.screen";
import SelfDevelopmentArticlesScreen from "./selfDevelopment/articles/selfDevelopmentArticles.screen";
import SelfDevelopmentVideosScreen from "./selfDevelopment/videos/selfDevelopmentVideos.screen";
import SelfDevelopmentStoriesScreen from "./selfDevelopment/stories/selfDevelopmentStories.screen";
import SelfDevelopmentBooksScreen from "./selfDevelopment/books/selfDevelopmentBooks.screen";
import ArchiveScreen from "./bhandara/archive/archive.screen";
import { TagsScreen } from "./tags/tags.screen";
import { MeditationTags } from "./tags/meditationTags.screen";
import { LiveSessionTags } from "./tags/liveSessionTags.screen";
import DonationList from "./donationList/donationList.screen";
import PdfsScreen from "./heartsApp/pdfs/pdf.screen";
import HeartsAppVideos from "./heartsApp/heartsappVideos/heartsapp_videos.screen";
import BhandaraTagsScreen from "./bhandara/tags/BhandaraTagsScreen";
import DaajiMessage from "./bhandara/daajiMessage/DaajiMessage";
import YatraGarden from "./bhandara/yatraGarden/YatraGarden";
import TaskScreen from "./task/task.screen";
import ThemeScreen from "./theme/theme.screen";
import HomeBanner from "./bhandara/homeBanner/HomeBanner";

import HomeScreenCardsScreen from "./bhandara/cards/cardsOrder.screen";
import CampaignsScreen from "./campaigns/campaign.screen";

export default function AppScreen(): ReactElement {
  const { path } = useRouteMatch();

  const users: any = localStorage.getItem("user");
  const user = JSON.parse(users);

  useEffect(() => {
    initializePushNotifications();
  }, []);
  // console.log('path',path)
  // const state = useSelector((state: RootState) => state.user);

  // restricted = false meaning public route
  // restricted = true meaning restricted route
  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/${user.role === 8 ? "events" : "live"}`} />
        </Route>
        <PublicRoute
          path={`${path}/pre-recorded-videos`}
          restricted={
            user.role === 1 ||
            user.role === 2 ||
            user.role === 3 ||
            user.role === 5
              ? false
              : true
          }
          component={PreRecordedVideosScreen}
        />
        <PublicRoute
          path={`${path}/schedule-pre-recorded-videos`}
          restricted={
            user.role === 1 || user.role === 2 || user.role === 3 ? false : true
          }
          component={SchedulePreRecordedVideoScreen}
        />
        <PublicRoute
          path={`${path}/users`}
          restricted={user.role === 1 ? false : true}
          component={UsersScreen}
        />
        <PublicRoute
          path={`${path}/dashboard`}
          restricted={user.role === (5 && 9) ? true : false}
          component={DashboardScreen}
        />
        <PublicRoute
          path={`${path}/campaigns`}
          restricted={user.role === (5 && 9) ? true : false}
          component={CampaignsScreen}
        />
        <PublicRoute
          path={`${path}/guided`}
          restricted={user.role === (5 && 9) ? true : false}
          component={GuidedMeditationScreen}
        />
        <PublicRoute
          path={`${path}/purpose`}
          restricted={user.role === (5 && 9) ? true : false}
          component={MeditationWithPurposeScreen}
        />
        <PublicRoute
          path={`${path}/meditation_background`}
          restricted={user.role === (5 && 9) ? true : false}
          component={MeditaionBackground}
        />
        <PublicRoute
          path={`${path}/purpose_categories`}
          restricted={user.role === (5 && 9) ? true : false}
          component={PurposeCategoryScreen}
        />
        <PublicRoute
          path={`${path}/guided-meditation-tags`}
          restricted={user.role === (5 && 9) ? true : false}
          component={MeditationTags}
        />
        <PublicRoute
          path={`${path}/live`}
          restricted={user.role === (3 && 9) ? true : false}
          component={LiveMeditationScreen}
        />
        <PublicRoute
          path={`${path}/define_session`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={CreateLiveSessionScreen}
        />
        <PublicRoute
          path={`${path}/assign_session`}
          restricted={user.role === (3 && 9) ? true : false}
          component={AssignLiveSessionScreen}
        />
        <PublicRoute
          path={`${path}/live-session-tags`}
          restricted={user.role === (3 && 9) ? true : false}
          component={LiveSessionTags}
        />
        <PublicRoute
          path={`${path}/coaches`}
          restricted={user.role === 5 || user.role === (3 && 9) ? true : false}
          component={CoachesScreen}
        />
        <PublicRoute
          path={`${path}/faq`}
          restricted={user.role === 5 || user.role === (3 && 9) ? true : false}
          component={faqScreen}
        />
        <PublicRoute
          path={`${path}/goals`}
          restricted={user.role === (5 && 9) ? true : false}
          component={GoalsScreen}
        />
        <PublicRoute
          path={`${path}/goalsv2`}
          restricted={user.role === (5 && 9) ? true : false}
          component={GoalsV2Screen}
        />
        <PublicRoute
          path={`${path}/donations`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={DonationsScreen}
        />
        <PublicRoute
          path={`${path}/donations_list`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={DonationList}
        />
        <PublicRoute
          path={`${path}/pdfs`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={PdfsScreen}
        />
        <PublicRoute
          path={`${path}/heartsapp_videos`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={HeartsAppVideos}
        />
        <PublicRoute
          path={`${path}/moods`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={MoodsScreen}
        />
        <PublicRoute
          path={`${path}/tags`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={TagsScreen}
        />
        <PublicRoute
          path={`${path}/triggers`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={TriggersScreen}
        />
        <PublicRoute
          path={`${path}/articles`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={ArticlesScreen}
        />
        <PublicRoute
          path={`${path}/blogs`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={BlogsScreen}
        />

        <PublicRoute
          path={`${path}/quotes`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={QuotesScreen}
        />
        <PublicRoute
          path={`${path}/videos`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={VideosScreen}
        />
        <PublicRoute
          path={`${path}/filters`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={FiltersScreen}
        />
        <PublicRoute
          path={`${path}/self_development_articles`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={SelfDevelopmentArticlesScreen}
        />
        <PublicRoute
          path={`${path}/self_development_stories`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={SelfDevelopmentStoriesScreen}
        />
        <PublicRoute
          path={`${path}/self_development_videos`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={SelfDevelopmentVideosScreen}
        />
        <PublicRoute
          path={`${path}/self_development_books`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={SelfDevelopmentBooksScreen}
        />
        <PublicRoute
          path={`${path}/layout`}
          restricted={user.role === 1 ? false : true}
          component={LayoutmanagerScreen}
        />
        <PublicRoute
          path={`${path}/home-screen-cards`}
          restricted={user.role === 1 || user.role === 2 ? false : true}
          component={HomeScreenCardsScreen}
        />
        <PublicRoute
          path={`${path}/events`}
          restricted={user.role === 1 || user.role === 8 ? false : true}
          component={EventsScreen}
        />
        <PublicRoute
          path={`${path}/locations`}
          restricted={user.role === 1 ? false : true}
          component={LocationsScreen}
        />
        <PublicRoute
          path={`${path}/lnf`}
          restricted={user.role === 1 ? false : true}
          component={LostAndFoundScreen}
        />
        <PublicRoute
          path={`${path}/essentials`}
          restricted={user.role === 1 ? false : true}
          component={EssentialServicesScreen}
        />
        <PublicRoute
          path={`${path}/homebanner`}
          restricted={user.role === 1 ? false : true}
          component={HomeBanner}
        />
        <PublicRoute
          path={`${path}/announcements`}
          restricted={user.role === 1 || user.role === 8 ? false : true}
          component={AnnouncementsScreen}
        />
        <PublicRoute
          path={`${path}/archive`}
          restricted={user.role === 1 ? false : true}
          component={ArchiveScreen}
        />
        <PublicRoute
          path={`${path}/bhandara-tags`}
          restricted={user.role === 1 ? false : true}
          component={BhandaraTagsScreen}
        />
        <PublicRoute
          path={`${path}/daaji's-message`}
          restricted={user.role === 1 ? false : true}
          component={DaajiMessage}
        />
        <PublicRoute
          path={`${path}/yatra-garden`}
          restricted={user.role === 1 ? false : true}
          component={YatraGarden}
        />
        <PublicRoute
          component={TaskScreen}
          restricted={
            user.role === 1 || user.role === 2 || user.role === 9 ? false : true
          }
          path={`${path}/task`}
        />
        <PublicRoute
          component={ThemeScreen}
          restricted={
            user.role === 1 || user.role === 2 || user.role === 9 ? false : true
          }
          path={`${path}/theme`}
        />
      </Switch>
    </DashboardLayout>
  );
}
